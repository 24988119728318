import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Navbar,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  ModalHeader,
  ModalBody,
  Modal,
  Col,
  Row,
  Table,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Icon from "react-feather";
import logo from "../../assets/images/logos/logo.png";
import {
  ToggleMiniSidebar,
  ToggleMobileSidebar,
} from "../../store/customizer/CustomizerSlice";
import Marquee from "react-fast-marquee";
import phonepe from "../../assets/images/icons/phonepe.png";
import gpay from "../../assets/images/icons/gpay.png";
import paytm from "../../assets/images/icons/paytm.png";
import upi from "../../assets/images/icons/upi.png";
import { logout } from "../../slices/authSlice/userSilce";
import { fetchData } from "../../slices/authSlice/dataSlice";
import user1 from "../../assets/images/users/user1.jpg";
import userImg from "../../assets/images/distributorDashboard/user.jpg";
import ProfileDD from "./ProfileDD";
// import img1 from "../../assets/images/Retailer/DMR-MONEY.png";
import MyDistributorPopBox from "../../components/retailer/MyDistributorPopBox";
import smsIcom from "../../assets/images/SuperDistributorDashboard/note-icon-02.svg";
import adminIcon from "../../assets/images/admin/4.jpg";

const Header = () => {
  const isDarkMode = useSelector((state) => state.customizer.isDark);
  const topbarColor = useSelector((state) => state.customizer.topbarBg);
  const { user: currentUser } = useSelector((state) => state.user);
  const { token: Usertoken } = useSelector((state) => state.user);

  // const balanceUpdate = useSelector((state) => state.userdetails.userDeleteSuccess);
  // const [userBalance, setUserBalance] = useState(0);
  const [proData, setProData] = useState([]);
  const [QRimage] = useState();
  const [UPIID] = useState();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const baseB4 = "data:image/jpeg;base64,";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchData({ Usertoken, currentUser }));
  }, [dispatch]);
  const loginData = useSelector((state) => state.data.items);
  const handleLogoutClick = () => {
    window.addEventListener("beforeunload", () => {
      dispatch(logout());
    });
    navigate("/", { replace: true });
    window.location.reload();
  };

  const toggle = () => {
    setModal(!modal);
  };
  // const handleRefresh = () => {
  //   window.location.reload();
  // };
  // console.log(loginData);
  return (
    <>
      <Navbar
        color={
          currentUser.role == "1" ||
          currentUser.role == "2" ||
          currentUser.role == "8"
            ? topbarColor
            : topbarColor
        }
        dark={!isDarkMode}
        light={isDarkMode}
        expand="lg"
        className={`${
          currentUser.role == "1" ||
          currentUser.role == "2" ||
          currentUser.role == "8"
            ? "topbarAdmin admin_head_bg"
            : "topbar navbar-bg-color"
        } ${
          currentUser.role == "1" || currentUser.role == "2" ? "px-md-2" : ""
        }`}
        loading={loading}
      >
        {currentUser.role == "1" ? (
          <div className="admin-nav">
            <div className="pr-15">
              <Button
                color={topbarColor}
                className="toggle-btn desktop-toggle"
                onClick={() => dispatch(ToggleMiniSidebar())}
              >
                <Icon.Menu size={18} />
              </Button>
              <Button
                color={topbarColor}
                className="toggle-btn tab-toggle"
                onClick={() => dispatch(ToggleMobileSidebar())}
              >
                <i className="bi bi-list" />
              </Button>
            </div>
            <div className="">
              <Link class="pc-head-link dmr-money" to="#">
                <i class="far fa-calendar-alt topIcon"></i>
              </Link>
              <Link class="pc-head-link dmr-money" to="#">
                <i class="far fa-bell topIcon"></i>
              </Link>
              <Link class="pc-head-link dmr-money" to="#">
                <i class="far fa-envelope topIcon"></i>
              </Link>
              <Link class="pc-head-link card-btn" to="#">
                Fund Request
              </Link>
              <span className="pc-head-link">
                Main Wallet : {`\u20B9`}{" "}
                {loginData ? loginData?.main_Wallet_Balance : "0.00"}
              </span>
              <UncontrolledDropdown className="user-box">
                <DropdownToggle color="transparent">
                  <div className="d-flex align-items-center">
                    <img src={adminIcon} alt="" className="adminIcon" />
                    <span className="user-name-style">
                      {loginData?.firstName} {loginData?.lastName}
                    </span>
                    <Icon.ChevronDown size={15} color="#3fa2db" />
                  </div>
                </DropdownToggle>
                <DropdownMenu className="ddWidth profile-dd">
                  <ProfileDD />
                  <div className="p-2 px-3">
                    <Button
                      color="danger"
                      size="sm"
                      onClick={handleLogoutClick}
                    >
                      Logout
                    </Button>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        ) : null}
        {currentUser.role == "2" ? (
          <>
            <Nav
              className="text-center d-flex flex-row align-items-center"
              navbar
            >
              <NavItem className="d-md-block d-none">
                <Link
                  to="#"
                  className={`nav-link hov-dd ${
                    topbarColor === "white" ? "text-dark" : ""
                  }`}
                >
                  <h4 className="m-auto fw-bold">
                    {" "}
                    Dashboard - WELCOME, Business Partner Dahboard!
                  </h4>
                </Link>
              </NavItem>
            </Nav>
            <UncontrolledDropdown className="user-box">
              <DropdownToggle color="transparent">
                <div className="d-flex align-items-center">
                  <i class="far fa-user userstyle"></i>
                  <span className="user-name-style">
                    {loginData?.firstName} {loginData?.lastName}
                  </span>
                  <Icon.ChevronDown size={15} color="#3fa2db" />
                </div>
              </DropdownToggle>
              <DropdownMenu className="ddWidth profile-dd">
                <ProfileDD />
                <div className="p-2 px-3">
                  <Button color="danger" size="sm" onClick={handleLogoutClick}>
                    Logout
                  </Button>
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        ) : null}
        {currentUser.role == "8" ? (
          <>
            <Nav
              className="text-center d-flex flex-row align-items-center"
              navbar
            >
              <NavItem className="d-md-block d-none">
                <Link
                  to="#"
                  className={`nav-link hov-dd ${
                    topbarColor === "white" ? "text-dark" : ""
                  }`}
                >
                  <h4 className="m-auto fw-bold">
                    {" "}
                    Dashboard - WELCOME, Super Admin Dahboard!
                  </h4>
                </Link>
              </NavItem>
            </Nav>
            <UncontrolledDropdown className="user-box">
              <DropdownToggle color="transparent">
                <div className="d-flex align-items-center">
                  <i class="far fa-user userstyle"></i>
                  <span className="user-name-style">
                    {loginData?.firstName} {loginData?.lastName}
                  </span>
                  <Icon.ChevronDown size={15} color="#3fa2db" />
                </div>
              </DropdownToggle>
              <DropdownMenu className="ddWidth profile-dd">
                <ProfileDD />
                <div className="p-2 px-3">
                  <Button color="danger" size="sm" onClick={handleLogoutClick}>
                    Logout
                  </Button>
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        ) : null}
        {currentUser.role == "6" ? (
          <div className="agent-nav">
            <div className="pr-15">
              <Button
                color={topbarColor}
                className="toggle-btn desktop-toggle"
                onClick={() => dispatch(ToggleMiniSidebar())}
              >
                <Icon.Menu size={18} />
              </Button>
              <Button
                color={topbarColor}
                className="toggle-btn tab-toggle"
                onClick={() => dispatch(ToggleMobileSidebar())}
              >
                <i className="bi bi-list" />
              </Button>
            </div>
            <div className="">
              <Link class="pc-head-link dmr-money" to="#">
                <i class="fa fa-phone phone"></i> &nbsp; Travel Helpline :
                9898989898
              </Link>
              <Link
                class="pc-head-link top-btn"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                My Distributor
              </Link>

              <Link class="pc-head-link dmr-money youtub-icon-style" to="#">
                <i class="fa fa-play"></i>
              </Link>

              <Link class="pc-head-link dmr-money" to="#">
                {/* <img src={img1} /> */}
              </Link>
              <span className="pc-head-link">
                <i class="fas fa-wallet wcolor"></i> AEPS Wallet : {`\u20B9`}{" "}
                {loginData ? loginData?.aepS_Wallet_Balance : "0.00"}
              </span>
              <span className="pc-head-link">
                <i class="fas fa-wallet wcolor"></i> Main Wallet : {`\u20B9`}{" "}
                {loginData ? loginData?.main_Wallet_Balance : "0.00"}
              </span>
              <UncontrolledDropdown className="user-box">
                <DropdownToggle color="transparent">
                  <div className="d-flex align-items-center">
                    <i class="far fa-user userstyle"></i>
                    <span className="user-name-style">
                      {loginData?.firstName} {loginData?.lastName}
                    </span>
                    <Icon.ChevronDown size={15} color="#3fa2db" />
                  </div>
                </DropdownToggle>
                <DropdownMenu className="ddWidth profile-dd">
                  <ProfileDD />
                  <div className="p-2 px-3">
                    <Button
                      color="danger"
                      size="sm"
                      onClick={handleLogoutClick}
                    >
                      Logout
                    </Button>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        ) : null}
        {currentUser.role == "5" ? (
          <div className="distributor-nav">
            <div className="">
              <Button
                color={topbarColor}
                className="toggle-btn tab-toggle"
                onClick={() => dispatch(ToggleMobileSidebar())}
              >
                <i className="bi bi-list" />
              </Button>
            </div>
            <div className="">
              <span className="pc-head-link">
                <i class="fas fa-wallet wcolor"></i> Main Wallet : {`\u20B9`}{" "}
                {loginData ? loginData?.main_Wallet_Balance : "0.00"}
              </span>
              <UncontrolledDropdown className="user-box">
                <DropdownToggle color="transparent">
                  <div className="d-flex align-items-center">
                    <img src={userImg} width="50" alt="" />
                    <span className="user-name-style">
                      {loginData?.firstName} {loginData?.lastName}
                    </span>
                    <Icon.ChevronDown size={15} color="#3fa2db" />
                  </div>
                </DropdownToggle>
                <DropdownMenu className="ddWidth profile-dd popupHolder">
                  <ProfileDD />
                  <div className="p-2 px-3">
                    <Button
                      color="danger"
                      size="sm"
                      onClick={handleLogoutClick}
                    >
                      Logout
                    </Button>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        ) : null}
        {currentUser.role == "4" ? (
          <div className="super-distributor-nav">
            <div className="logo-holder">
              <Link
                to="/dashboards/superdistributordashboard"
                className="p-2-logo"
              >
                <img src={logo} alt="profile" width="80px" height="auto" />
              </Link>
              <Button
                color={topbarColor}
                className="toggle-btn desktop-toggle"
                onClick={() => dispatch(ToggleMiniSidebar())}
              >
                <Icon.Menu size={18} />
              </Button>
              <Button
                color={topbarColor}
                className="toggle-btn tab-toggle"
                onClick={() => dispatch(ToggleMobileSidebar())}
              >
                <i className="bi bi-list" />
              </Button>
            </div>
            <div className="">
              <span className="pc-head-link">
                <Marquee gradient={false} speed={60}>
                  <span className="marq-text"> No Notice Available </span>
                </Marquee>
              </span>
              <span className="pc-head-link sms-icon-holder">
                <img src={smsIcom} alt="" />
                <span className="pulse"></span>
              </span>

              <span className="pc-head-link">
                <i class="fas fa-wallet wcolor"></i> Main Wallet : {`\u20B9`}{" "}
                {loginData ? loginData?.main_Wallet_Balance : "0.00"}
              </span>
              <UncontrolledDropdown className="user-box">
                <DropdownToggle color="transparent">
                  <div className="d-flex align-items-center">
                    <Icon.ChevronDown
                      size={15}
                      color="#3fa2db"
                      className="d-none"
                    />
                    <img src={userImg} width="50" alt="" />
                    <div className="user-name-style">
                      {loginData?.firstName} {loginData?.lastName}
                    </div>
                  </div>
                </DropdownToggle>
                <DropdownMenu className="ddWidth profile-dd popupHolder">
                  <ProfileDD />
                  <div className="p-2 px-3">
                    <Button
                      color="danger"
                      size="sm"
                      onClick={handleLogoutClick}
                    >
                      Logout
                    </Button>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        ) : null}
        <div className="d-flex align-items-center">
          {/* {currentUser.role === '1' ? (
            <>
              <div className="p-0 px-2">
                <Link to="/Dashboards/AdminDashboard">
                  <Button color="info" size="md">
                    Dashboard
                  </Button>
                </Link>
              </div>
            </>
          ) : null} */}
          {/* {currentUser.role === '1' ? (
            <>
              <div className="p-0 px-2 mx-4">
                <Button color="info" size="md" onClick={() => handleRefresh()}>
                  Refresh
                </Button>
              </div>
              <div className="pt-0 px-3" onClick={() => toggle()}>
                <img src={qr} alt="qr" style={{ width: '30px', background: '#6732a7' }} />
              </div>
            </>
          ) : null} */}
          <div className="pt-2 px-1 d-flex d-none">
            <div>
              {currentUser.role == "6" ? (
                <h4 className="text-warning fw-normal">
                  AEPS Wallet : {`\u20B9`}{" "}
                  {loginData ? loginData?.aepS_Wallet_Balance : "0.00"}
                </h4>
              ) : null}
            </div>
            <h4 className="text-warning fw-normal  px-3">
              Main Wallet : {`\u20B9`}{" "}
              {loginData ? loginData?.main_Wallet_Balance : "0.00"}
            </h4>
          </div>

          <UncontrolledDropdown className="d-none">
            <DropdownToggle color="transparent">
              <div className="d-flex align-items-center">
                <img
                  src={baseB4 + proData}
                  alt="user"
                  width="30"
                  className="rounded-circle d-none"
                />
                <img
                  src={user1}
                  alt="user"
                  width="30px"
                  height="auto"
                  className="rounded-circle mx-1"
                />
                <span className="text-info">
                  {loginData?.firstName} {loginData?.lastName}
                </span>
                <Icon.ChevronDown size={15} color="#3fa2db" />
              </div>
            </DropdownToggle>
            <DropdownMenu className="ddWidth profile-dd">
              <ProfileDD />
              <div className="p-2 px-3">
                <Button color="danger" size="sm" onClick={handleLogoutClick}>
                  Logout
                </Button>
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </Navbar>
      {modal ? (
        <Col xs="8" md="12">
          <Modal isOpen={toggle} fade={false} toggle={toggle.bind(null)}>
            <ModalHeader
              toggle={toggle.bind(null)}
              style={{ padding: "10px", color: "#000" }}
            >
              UPI Collection
            </ModalHeader>
            <ModalBody>
              <Row className="row">
                <Col md="4">
                  <img
                    src={baseB4 + QRimage}
                    alt="123"
                    style={{ width: "150px", border: "1px solid #dfe2e6" }}
                  />
                </Col>
                <Col md="8" className="mt-0">
                  <Table className="table-bordered">
                    <thead>
                      <tr>
                        <td className="text-nowrap text-black">Name </td>
                        <td>
                          {loginData.firstName}
                          {loginData.lastName}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-nowrap text-black">UPI ID </td>

                        <td>{UPIID}</td>
                      </tr>
                    </thead>
                  </Table>
                  <Row>
                    <img
                      src={phonepe}
                      alt="phonepe"
                      style={{ width: "60px" }}
                    />
                    <img src={gpay} alt="gpay" style={{ width: "60px" }} />
                    <img src={upi} alt="upi" style={{ width: "60px" }} />
                    <img src={paytm} alt="paytm" style={{ width: "60px" }} />
                  </Row>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </Col>
      ) : null}
      <MyDistributorPopBox />
    </>
  );
};

export default Header;
