import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import Header from './header/Header';
import Sidebar from './sidebars/vertical/Sidebar';
import HorizontalHeader from './header/HorizontalHeader';
import HorizontalSidebar from './sidebars/horizontal/HorizontalSidebar';
import AppLogout from '../components/common/AppLogout';

const AdminLayout = () => {
  const toggleMiniSidebar = useSelector((state) => state.customizer.isMiniSidebar);
  const showMobileSidebar = useSelector((state) => state.customizer.isMobileSidebar);
  const topbarFixed = useSelector((state) => state.customizer.isTopbarFixed);
  const LayoutHorizontal = useSelector((state) => state.customizer.isLayoutHorizontal);
  const isFixedSidebar = useSelector((state) => state.customizer.isSidebarFixed);

  return (
    <main className='admin-bg'>
    <div
      className={`pageWrapper d-md-block d-lg-flex ${toggleMiniSidebar ? 'isMiniSidebar' : ''}`}
    >
      {LayoutHorizontal ? (
        ''
      ) : (
        <aside className={`sidebarArea ${showMobileSidebar ? 'showSidebar' : ''}`}>
          <Sidebar />
        </aside>
      )}
      <div className={`contentArea ${topbarFixed ? 'fixedTopbar' : ''}`}>
        <AppLogout>
          {LayoutHorizontal ? <HorizontalHeader /> : <Header />}
          {LayoutHorizontal ? <HorizontalSidebar /> : ''}
          <Container fluid className="boxContainer">
            <div className={isFixedSidebar && LayoutHorizontal ? 'HsidebarFixed' : ''}>
              <Outlet />
            </div>
          </Container>
        </AppLogout>
      </div>
    </div>
  </main>
  );
};

export default AdminLayout;
