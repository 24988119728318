import { createSlice } from '@reduxjs/toolkit';
import { createEncryptedAsyncThunk } from '../../Utility/APIUtils';

const initialState = {
  benDeleteSuccess: false,
  error: '',
  loading: false,
  creditAmountSucess: false,
  creditAmountResponse: {
    error: false,
    message: '',
  },
  commonDeleteResponse: {
    status: false,
    message: '',
  },
};

export const IservueOnboarding = createEncryptedAsyncThunk(
  'AepsSlice/IservueOnboarding',
  'account/ISU_AgentOnboarding',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      email: params.body.emailID,
      bcagentid: params.currentUser.userName,
      bcagentname: params.body.firstName,
      lastname: params.body.lastName,
      companyname: params.body.shopName,
      address: params.body.Address,
      area: params.body.Address,
      pincode: Number(params.body.pinCode),
      mobilenumber: params.loginData.primaryPhone,
      shopname: params.body.shopName,
      shopaddress: params.body.ShopAddress,
      shopstate: params.body.state,
      shopcity: params.body.city,
      shopdistrict: params.body.city,
      shoparea: params.body.ShopAddress,
      shoppincode: Number(params.body.pinCode),
      pancard: params.body.panNumber,
    }),
  },
);
export const getTransactionIDAEPS = createEncryptedAsyncThunk(
  'AepsSlice/onboarUser',
  'account/ISU_AEPSTransaction',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      transactionType: params.type,
      customerNo: params.custMobileNumber,
    }),
  },
);
export const onboarUser = createEncryptedAsyncThunk(
  'AepsSlice/onboarUser',
  'transaction/MerchantOnboarding',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      merchantcode: params.mobileNumber,
      mobile: params.mobileNumber,
      is_new: '0',
      email: params.userEmail,
      firm: params.businessName,
      callback: process.env.REACT_APP_PS_CALLBACKONBOARDINGURL,
      serviceId: params.serviceId.serviceId,
    }),
  },
);
export const AepsDailyAuthntication = createEncryptedAsyncThunk(
  'AepsSlice/AepsAuthnticationRegistration',
  'transaction/AEPSAuthenticate',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      accessmodetype: 'SITE',
      adhaarnumber: params.adhaarNumber,
      mobilenumber: params.customerMobile,
      latitude: `${params.location.lattitude}`,
      longitude: `${params.location.longitude}`,
      referenceno: '',
      submerchantid: params.customerMobile,
      timestamp: '',
      data: params.captureData,
      ipaddress: params.location.ip,
      serviceId: params.serviceId.serviceId,
    }),
  },
);
export const AepsAuthnticationRegistration = createEncryptedAsyncThunk(
  'AepsSlice/AepsAuthnticationRegistration',
  'transaction/AEPSRegistration',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      accessmodetype: 'SITE',
      adhaarnumber: params.adhaarNumber,
      mobilenumber: params.customerMobile,
      latitude: `${params.location.lattitude}`,
      longitude: `${params.location.longitude}`,
      // referenceno: '',
      submerchantid: params.customerMobile,
      // timestamp: '',
      // submerchantid: "10001",
      data: params.captureData,
      ipaddress: params.location.ip,
      serviceId: params.serviceId.serviceId,
    }),
  },
);
export const AepsDailyAuthnticationAgent = createEncryptedAsyncThunk(
  'AepsSlice/AepsDailyAuthnticationAgent',
  'account/AEPSMerchantAuthenticity',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      accessmodetype: 'SITE',
      adhaarnumber: params.adharNumber,
      mobilenumber: params.loginData.primaryPhone,
      latitude: `${params.location.lattitude}`,
      longitude: `${params.location.longitude}`,
      referenceno: '',
      submerchantid: params.loginData.primaryPhone,
      timestamp: '',
      data: params.captureData,
      ipaddress: params.location.ip,
    }),
  },
);

export const AepsCaptureTransaction = createEncryptedAsyncThunk(
  'AepsSlice/AepsCaptureTransaction',
  'transaction/AEPSWithdrawl',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      wallettype: 2,
      tpin: "1234",
      // serviceId: `${params.serviceId.serviceId}`,
      transfer_Type: "AEPS",
      latitude: `${params.location.lattitude}`,
      longitude: `${params.location.longitude}`,
      mobilenumber: params.customerMobile,
      referenceno: "",
      ipaddress: params.location.ip,
      adhaarnumber: params.adharNumber,
      accessmodetype: "SITE",
      nationalbankidentification: `${params.selectBank.value}`,
      requestremarks: "AEPS Transaction",
      data: params.captureData,
      // pipe: "bank2",
      pipe: "bank1",  //UAT
      timestamp: "",
      transactiontype: params.transactionType,
      submerchantid: params.loginData.primaryPhone,
      amount: Number(params.amount),
      is_iris: "No",
      merAuthTxnId: params.merAuth,
      serviceId: params.serviceId.serviceId,
    }),
  },
);

export const AepsCaptureAadharPay = createEncryptedAsyncThunk(
  "AepsSlice/AepsCaptureTransaction",
  "transaction/AadharPay",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      wallettype: 2,
      tpin: "1234",
      latitude: `${params.location.lattitude}`,
      longitude: `${params.location.longitude}`,
      mobilenumber: params.customerMobile,
      referenceno: "",
      ipaddress: params.location.ip,
      adhaarnumber: params.adharNumber,
      accessmodetype: "SITE",
      nationalbankidentification: `${params.selectBank.value}`,
      requestremarks: "AEPS Transaction",
      data: params.captureData,
      // pipe: "bank2",
      pipe: "bank1",   //for UAT
      timestamp: params.timestamp || "",
      transactiontype: params.transactionType,
      submerchantid: params.loginData.primaryPhone,
      amount: Number(params.amount),
      is_iris: "No",
      serviceId: params.serviceId.serviceId,
    }),
  }
);

export const AepsCaptureTransactionMiniStatement = createEncryptedAsyncThunk(
  'AepsSlice/AepsCaptureTransaction',
  'transaction/AEPSMiniStatement',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      wallettype: 2,
      tpin: "1234",
      // serviceId: `${params.serviceId.serviceId}`,
      transfer_Type: "AEPS",
      latitude: `${params.location.lattitude}`,
      longitude: `${params.location.longitude}`,
      mobilenumber: params.customerMobile,
      referenceno: "",
      ipaddress: params.location.ip,
      adhaarnumber: params.adharNumber,
      accessmodetype: "SITE",
      nationalbankidentification: `${params.selectBank.value}`,
      requestremarks: "AEPS Transaction",
      data: params.captureData,
      // pipe: "bank2",
      pipe: "bank1", //UAT
      timestamp: "",
      transactiontype: params.transactionType,
      submerchantid: params.loginData.primaryPhone,
      amount: Number(params.amount),
      is_iris: "No",
      merAuthTxnId: params.merAuth,
      serviceId: params.serviceId.serviceId,
    }),
  },
);
export const AepsCaptureTransactionBalanceEnq = createEncryptedAsyncThunk(
  'AepsSlice/AepsCaptureTransactionBalanceEnq',
  'transaction/AEPSEnquiry',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      // userid: Number(params.currentUser.userId),
      // wallettype: 2,
      // tpin: '1234',
      // serviceId: `${params.serviceId.serviceId}`,
      // transfer_Type: 'AEPS',
      // latitude: `${params.location.lattitude}`,
      // longitude: `${params.location.longitude}`,
      // mobilenumber: params.customerMobile,
      // referenceno: '',
      // ipaddress: params.location.ip,
      // adhaarnumber: params.adharNumber,
      // accessmodetype: 'SITE',
      // nationalbankidentification: `${params.selectBank.value}`,
      // requestremarks: 'AEPS Transaction',
      // data: params.captureData,
      // pipe: 'bank2',
      // timestamp: '',
      // transactiontype: params.transactionType,
      // submerchantid: params.loginData.primaryPhone,
      // amount: Number(params.amount),
      // is_iris: 'No',
      // merAuthTxnId: params.merAuth,

      userID: params.currentUser.userGuid,
      latitude: `${params.location.lattitude}`,
      longitude: `${params.location.longitude}`,
      mobilenumber: params.customerMobile,
      ipaddress: params.location.ip,
      adhaarnumber: params.adharNumber,
      accessmodetype: "SITE",
      nationalbankidentification: `${params.selectBank.value}`,
      requestremarks: "AEPS Transaction",
      data: params.captureData,
      // pipe: "bank2",
       pipe: "bank1", //UAT
      transactiontype: params.transactionType,
      is_iris: "No",
      serviceId: params.serviceId.serviceId,
    }),
  },
);
export const getTransactionAEPS = createEncryptedAsyncThunk(
  'AepsSlice/getTransactionAEPS',
  // 'account/GetAEPSPSReport',
  'account/GetAEPSReport',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      report_userid: params.currentUser.userId,
      txn_id: params.TransactionID,
      apiId: 0,
      startDate: null,
      endDate: null,
    }),
  },
);
export const serachCustomerAepsIsevu = createEncryptedAsyncThunk(
  'AepsSlice/serachCustomerAepsIsevu',
  'account/AEPSSearchUser',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      merchantCode: params.loginData.username,
      api_id: `${params.serviceId.api_id}`,
    }),
  },
);
export const serachCustomerAeps = createEncryptedAsyncThunk(
  'AepsSlice/serachCustomerAeps',
  'account/AEPSSearchUser',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      merchantCode: params.loginData.primaryPhone,
      api_id: `${params.serviceId.api_id}`,
    }),
  },
);
export const getBankList = createEncryptedAsyncThunk(
  'AepsSlice/getBankList',
  'Service/GetOperatorList',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      api_id: params.currentService.api_id,
      service_id: params.currentService.service_id,
      service_category: 'AEPSBANKLIST',
    }),
  },
);
export const getBankDetails = createEncryptedAsyncThunk(
  'AepsSlice/getBankDetails',
  'Service/GetOperatorList',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      api_id: params.currentService.api_id,
      service_id: params.currentService.service_id,
      service_category: 'AEPSBANKLIST',
    }),
  },
);
export const payoutUploadDoc = createEncryptedAsyncThunk(
  'AepsSlice/payoutUploadDoc',
  'account/PayoutAccountUpload',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      doctype: params.docType,
      passbook: params.passBookImg,
      panimage: params.panImage,
      front_aadhar: params.adharImage,
      back_aadhar: params.adharBack,
      bene_id: params.modalData.beneid,
    }),
  },
);
export const aepsTodmtWallet = createEncryptedAsyncThunk(
  'AepsSlice/aepsTodmtWallet',
  'account/WalletBalanceTransfer',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      toMobileNo: params.userName,
      req_Amt: Number(params.amount),
      from_wallet_type: 2,
      to_wallet_type: 1,
      req_remark: 'AEPSTODMT',
      tpin: params.Tpin,
    }),
  },
);
export const getAgentDetails = createEncryptedAsyncThunk(
  'AepsSlice/getAgentDetails',
  'Service/GetUserDetailsByMobNo',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      mobNo: params.body.mobile,
    }),
  },
);
export const getBeneficiaryList = createEncryptedAsyncThunk(
  'AepsSlice/getBeneficiaryList',
  'account/PayoutGetList',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      merchantid: params.loginData.primaryPhone,
    }),
  },
);
export const addNewBeneficiary = createEncryptedAsyncThunk(
  'AepsSlice/addNewBeneficiary',
  'account/PayoutAddAccount',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      // api_id: params.currentService.api_id,
      // service_id: params.currentService.service_id,
      // service_category: 'AEPSBANKLIST',
      userID: params.currentUser.userGuid,
      bankid: Number(params.selectBank.value),
      merchant_code: params.loginData.primaryPhone,
      account: params.accountNumber,
      ifsc: params.IfscCode,
      name: params.beneBificiaryName,
      account_type: params.accountType,
    }),
  },
);
export const verifyBeneficiayForAdd = createEncryptedAsyncThunk(
  'AepsSlice/verifyBeneficiayForAdd',
  'Service/GetOperatorList',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      api_id: params.currentService.api_id,
      service_id: params.currentService.service_id,
      service_category: 'AEPSBANKLIST',
    }),
  },
);
export const getTransactionDetails = createEncryptedAsyncThunk(
  'AepsSlice/getTransactionDetails',
  'transaction/GetServiceUsageDetails',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      serviceId: params.serviceId.serviceId,
      amount: Number(params.transactionAmount),
    }),
  },
);
export const transactionProcess = createEncryptedAsyncThunk(
  'AepsSlice/transactionProcess',
  'account/PayoutTransaction',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      serviceId: params.serviceId.serviceId,
      wallettype: 2,
      commision_ID: 0,
      tpin: params.tPin.tPin,
      submerchantid: params.loginData.primaryPhone,
      service_Charges: 0,
      bene_id: params.beniDetails.beneid,
      amount: params.data.transactionAmount,
      refid: 0,
      mode: params.data.selected,
    }),
  },
);

export const getAEPSTransactionHistory = createEncryptedAsyncThunk(
  'AepsSlice/transactionProcess',
  'account/GetAEPSReport',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      report_userID: params.currentUser.userId,
      txn_id: '',
      apiId: 0,
      startDate: params.fromDate,
      endDate: params.toDate,
    }),
  },
);
export const AepsSlice = createSlice({
  name: 'AepsSlice',
  initialState,
  reducers: {
    creditAmountSuccess(state) {
      return { ...state, creditAmountSucess: true };
    },
    creditAmountFailed(state, action) {
      return { ...state, creditAmountResponse: { error: true, message: action.payload } };
    },
    FetchSuccess(state, action) {
      return { ...state, recharge: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return { ...state, rechargeResponse: { status: true, message: action.payload } };
    },
    DeleteSuccess(state) {
      return { ...state, loading: false, benDeleteSuccess: true };
    },
    DeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        benDeleteSuccess: false,
        commonDeleteResponse: { status: true, message: action.payload },
      };
    },
    DeleteInitial(state) {
      return {
        ...state,
        benDeleteSuccess: false,
        commonDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const {
  FetchSuccess,
  FetchFailed,
  DeleteSuccess,
  DeleteFailed,
  DeleteInitial,
  creditAmountSuccess,
  creditAmountFailed,
} = AepsSlice.actions;
export default AepsSlice.reducer;
