import {
  configureStore,
  // applyMiddleware
} from '@reduxjs/toolkit';
// import CryptoMiddleWare from '../middleware/CryptoMiddleWare'
import CustomizerReducer from './customizer/CustomizerSlice';
// Common
import commonReducer from '../slices/Common/commonSlice'
// admin
import UserManagementReducer from '../slices/admin/UserManagement/UserManagementSlice';
import CommisionReducer from '../slices/admin/Commission/CommissionSlice';
import CreditDebitReducer from '../slices/admin/CreditDebit/CreditDebitSlice';
import manageBankReducer from '../slices/admin/ManageBank/manageBankSlice';
import adminReportsReducer from '../slices/admin/Reports/adminReportSlice';
import managementReducer from '../slices/admin/Management/managementSlice'; 
// Retailer
import userReducer from '../slices/authSlice/userSilce';
import userDetailsReducer from '../slices/retailer/UserAppSlice/UserDetailsSlice';
import dmtReducer from '../slices/retailer/DMT/DMTSlice';
import benificiaryReducer from '../slices/retailer/DMT/BenificiarySlice'
import transferRequestReducer from '../slices/TransferRequest/TransferRequestSlice'
import reportReducer from '../slices/retailer/Reports/ReportSlice'
import aepsReducer from '../slices/retailer/AEPS/AepsSlice';
import payoutReducer from '../slices/retailer/DMT/payOutSlice';
// Distributor
import manageRoleDistributorReducer from '../slices/distributor/manageRoleDistributor';
import manageRoleSPDistributorReducer from '../slices/spdistributor/manageRoleSPDistributor';
// user
import dataReducer from '../slices/authSlice/dataSlice';
// import getControlReducer from '../slices/authSlice/getControlSlice';
// import userDataReducer from '../slices/authSlice/userDataSlice';

export const store = configureStore(
  {
    reducer: {
      // user
      data: dataReducer,
      // controlData: getControlReducer,
      // userData: userDataReducer,
      // common
      common : commonReducer,
      // Retailer
      customizer: CustomizerReducer,
      user: userReducer,
      userdetails: userDetailsReducer,
      dmt: dmtReducer,
      benificiary : benificiaryReducer,
      transferRequest : transferRequestReducer,
      report : reportReducer,
      AepsSlice:aepsReducer,
      payOutSlice:payoutReducer,
      // admin
      UserManagement: UserManagementReducer,
      Commission: CommisionReducer,
      CreditDebit: CreditDebitReducer,
      manageBankSlice:manageBankReducer,
      adminReportSlice:adminReportsReducer,
      managementSlice:managementReducer,
      // Distributor
      manageRoleDistributor: manageRoleDistributorReducer,
      // SP Distributor
      manageRoleSPDistributor: manageRoleSPDistributorReducer,
    },
  },
  // applyMiddleware(CryptoMiddleWare)
);

export default store;
