// const HorizontalSidebarData = [
//   {
//     title: 'Dashboard',
//     href: '/Dashboards/AdminDashboard',
//     id: 1,
//     suffixColor: 'bg-info text-dark-white',
//     icon: <i className="bi bi-house"></i>,
//   },
//   {
//     title: 'User Management',
//     href: '#',
//     id: 2,
//     suffixColor: 'bg-info text-dark-white',
//     icon: <i className="bi bi-person"></i>,
//     collapisble: true,
//     children: [
//       // {
//       //   title: 'ROLE',
//       //   href: '/Role/AllRole',
//       //   id: 6,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: 'Business Partner',
//       //   href: '/UseManagement/BusinessPartner',
//       //   id: 6,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: 'Master Distributor',
//       //   href: '/UseManagement/MasterDistributor',
//       //   id: 10,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       {
//         title: 'Super Distributor',
//         href: '/UserManagement/SuperDistributor',
//         id: 6,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       {
//         title: 'Distributor Master',
//         href: '/Distributor/Distributor',
//         id: 10,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       {
//         title: 'Retailer Master',
//         href: '/Retailer/Retailer',
//         id: 14,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       {
//         title: 'User Service Status',
//         href: '/Admin/UserServiceStatus',
//         id: 14,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       {
//         title: 'User KYC Pending',
//         href: '/Admin/PendingKycVerification',
//         id: 14,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       // {
//       //   title: 'Employee List',
//       //   href: '/UseManagement/Employee',
//       //   id: 14,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: 'User Request',
//       //   href: '/Admin/UserRequest',
//       //   id: 14,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: 'Set User KYC Amount',
//       //   href: '/Admin/SetUserKycAmount',
//       //   id: 14,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//     ],
//   },

//   {
//     title:'Balance Request',
//     href: '#',
//     id: 2,
//     suffixColor: 'bg-info text-dark-white',
//     icon: <i className="bi bi-bag-check"></i>,
//     collapisble: true,
//     children: [
//       {
//         title: 'Balance Request',
//         href: '/Admin/ViewBalanceRequestAdmin',
//         id: 6,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//     ],
//   },
//   {
//     title: 'Commission',
//     href: '#',
//     id: 2,
//     suffixColor: 'bg-info text-dark-white',
//     icon: <i className="bi bi-bag-check"></i>,
//     collapisble: true,
//     children: [

//       {
//         title: 'Plan',
//         href: '/Admin/AllPlans',
//         id: 10,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       {
//         title:'Commission Plan Master',
//         href: '/Admin/GetCommissionPlanMaster',
//         id: 6,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//     ],
//   },
//   {
//     title: 'Credit Debit Details',
//     href: '#',
//     id: 2,
//     suffixColor: 'bg-info text-dark-white',
//     icon: <i className="bi bi-bag-check"></i>,
//     collapisble: true,
//     children: [
//       {
//         title:'Admin Self TopUp',
//         href: '/ManageCreditDetails/CreditSelfAdmin',
//         id: 6,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       {
//         title:'Direct TopUp User',
//         href: '/ManageCreditDetails/CreditDebitUsers',
//         id: 7,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       {
//         title:'Direct TopUp Reports',
//         href: '/Reports/AdminTopUpReport',
//         id: 8,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       {
//         title:'Credit Debit Details Reports',
//         href: '/ManageCreditDetails/CreditDebitDetails',
//         id: 9,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       // {
//       //   title: 'Plan',
//       //   href: '/PlanMaster/AllPlans',
//       //   id: 10,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: 'Commission Plan',
//       //   href: '/CommissionMaster/AllCommissionPlans',
//       //   id: 14,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//     ],
//   },
//   {
//     title: 'Manage Bank',
//     href: '#',
//     id: 2,
//     suffixColor: 'bg-info text-dark-white',
//     icon: <i className="bi bi-bag-check"></i>,
//     collapisble: true,
//     children: [
//       {
//         title:'Manage Self Bank',
//         href: '/Admin/AdminSelfBank',
//         id: 6,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       {
//         title: 'Manage Main Bank',
//         href: '/Admin/MainBankList',
//         id: 6,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       {
//         title: 'Bank Data Manager',
//         href: '/Admin/BankDataManager',
//         id: 6,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//     ],
//   },
//   {
//     title: 'Management',
//     href: '#',
//     id: 8,
//     suffixColor: 'bg-info text-dark-white',
//     icon: <i className="bi bi-eye"></i>,
//     collapisble: true,
//     children: [
//       // {
//       //   title:'Business Rights',
//       //   href: '/Admin/BusinessRights',
//       //   id: 44,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       {
//         title: 'Systems Setting',
//         href: '/Admin/SystemSetting',
//         id: 10,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       {
//         title:'Service Control',
//         href: '/Admin/ServicesControl',
//         id: 76,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       {
//         title:'Login History',
//         href: '/Admin/LoginHistory',
//         id: 80,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       {
//         title:'Complaint Manager',
//         href: '/Admin/ComplaintManager',
//         id: 6,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       {
//         title: 'Update CustomerLimit',
//         href: '/Admin/CustomerLimitInc',
//         id: 86,
//         suffixColor: '#ef7586',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       {
//         title: 'Update Customer Name',
//         href: '/Admin/CustomerNameUpdate',
//         id: 86,
//         suffixColor: '#ef7586',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       {
//         title: 'Change Tpin',
//         href: '/Admin/ChangeTpin',
//         id: 86,
//         suffixColor: '#ef7586',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       {
//         title:'Notification',
//         href: '/Admin/Notification',
//         id: 93,
//         suffixColor: '#ef7586',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       // {
//       //   title:'Add Banner',
//       //   href: '/Admin/AddBanner',
//       //   id: 93,
//       //   suffixColor: '#ef7586',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//     ],
//   },
//   {
//     title:'Reports',
//     href: '#',
//     id: 2,
//     suffixColor: 'bg-info text-dark-white',
//     icon: <i className="bi bi-bag-check"></i>,
//     collapisble: true,
//     children: [
//       {
//         title: 'User Ledger Reports',
//         href: '/Admin/AdminLedgeReports',
//         id: 6,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       {
//         title: 'User Ledger Reconsile Reports',
//         href: '/Admin/AdminLedgeReconsileReports',
//         id: 6,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       {
//         title: 'ADMIN Ledger Reports',
//         href: '/Admin/AdminSelfLedger',
//         id: 6,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       {
//         title:'Payout Transaction Reports',
//         href: '/Admin/PayOutTransactionReports',
//         id: 7,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       {
//         title:'Commission Reports',
//         href: '/Admin/CommissionReports',
//         id: 7,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },

//       {
//         title:'Transaction Reports',
//         href: '/Reports/AdminTransactionReport',
//         id: 7,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       {
//         title: 'Bill Payment Reports',
//         href: '/Reports/AdminBillPaymentReports',
//         id: 9,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       {
//         title:'AEPS Transaction Reports',
//         href: '/Reports/AEPSTransactionReportsAdmin',
//         id: 9,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       // {
//       //   title:'KYC Payment Status',
//       //   href: '/Reports/KycPaymentsStatus',
//       //   id: 9,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // Ayush 01-07-2024
//       // {
//       //   title: "Recharge Transactions",
//       //   href: "/Reports/AdminRechargeTransactions",
//       //   // id: 9,
//       //   suffixColor: "#4cb5bb",
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: "Recharge Pending Transactions",
//       //   href: "/Reports/AdminRechargePendingTransactions",
//       //   // id: 9,
//       //   suffixColor: "#4cb5bb",
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: "Recharge Refund Cases",
//       //   href: "/Reports/AdminRechargeRefundCases",
//       //   // id: 9,
//       //   suffixColor: "#4cb5bb",
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: "BBPS CMS Module",
//       //   href: "/Reports/AdminBBPSCMSModule",
//       //   // id: 9,
//       //   suffixColor: "#4cb5bb",
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // // End
//       // {
//       //   title: 'Money Transfer Report',
//       //   href: '/Reports/AdminMoneyTransferReport',
//       //   // id: 9,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: 'Money Transfer Hold Cases',
//       //   href: '/Reports/AdminMoneyTransferHoldCases',
//       //   // id: 9,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: 'AEPS Report',
//       //   href: '/Reports/AdminAepsReport',
//       //   // id: 9,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: 'Outstanding Report',
//       //   href: '/Reports/OutStandingReportsAdmin',
//       //   id: 7,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: 'CD Report',
//       //   href: '/Reports/AdminCDReport',
//       //   // id: 9,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: 'CC Report',
//       //   href: '/Reports/AdminCCReport',
//       //   // id: 9,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: 'Payment Getway Transaction Report',
//       //   href: '/Reports/AdminPaymentGetwayTransactionReport',
//       //   // id: 9,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: 'Collection Report',
//       //   href: '/Reports/AdminCollectionReport',
//       //   // id: 9,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: 'POS Transaction Report',
//       //   href: '/Reports/AdminPOSTransactionReport',
//       //   // id: 9,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: 'Wallet To Wallet Transfer Report',
//       //   href: '/Reports/AdminWalletToWalletTransferReport',
//       //   // id: 9,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//     ],
//   },

//   {
//     title: 'Account Report',
//     href: '#',
//     id: 2,
//     suffixColor: 'bg-info text-dark-white',
//     icon: <i className="bi bi-bag-check"></i>,
//     collapisble: true,
//     children: [
//       {
//         title: 'Account Status Report',
//         href: '/Reports/AccountStatusReports',
//         // id: 6,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       // {
//       //   title: 'Wallet Report',
//       //   href: '/Admin/AdminWalletReport',
//       //   id: 7,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: 'Full Day Report',
//       //   href: '/Reports/AccountReport/AdminFullDayReport',
//       //   // id: 6,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       {
//         title: 'Business Analysis Report',
//         href: '/Admin/AdminBusinessReport',
//         // id: 6,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       // {
//       //   title: 'ICIC Bank Account Statement',
//       //   href: '/Reports/AccountReport/AdminICICBankAccontStatStatement',
//       //   // id: 6,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: 'TDS Report Pancardwise ',
//       //   href: '/Reports/AccountReport/AdminTDSReportPancardWise',
//       //   // id: 6,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: 'KYC Pending Report',
//       //   href: '/Reports/AccountReport/AdminKYCPendingReport',
//       //   // id: 6,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: 'KYC Approve Report',
//       //   href: '/Reports/AccountReport/AdminKYCApproveReport',
//       //   // id: 6,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: 'User Report',
//       //   href: '/Reports/AccountReport/AdminUserReport',
//       //   // id: 6,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: 'Users List',
//       //   href: '/Reports/AccountReport/AdminUserList',
//       //   // id: 6,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: 'Cash Out Report',
//       //   href: '/Reports/AccountReport/AdminCashOutReport',
//       //   // id: 6,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       {
//         title: 'Top Up Report',
//         href: '/Reports/AdminTopUpReport',
//         // id: 6,
//         suffixColor: '#4cb5bb',
//         icon: <i className="bi bi-chevron-right"></i>,
//         collapisble: true,
//       },
//       // {
//       //   title: 'DMT Log Report',
//       //   href: '/Reports/AccountReport/AdminDMTLogReport',
//       //   // id: 6,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//       // {
//       //   title: 'Verification Charge Report',
//       //   href: '/Reports/AccountReport/AdminVirificationChargeReport',
//       //   // id: 6,
//       //   suffixColor: '#4cb5bb',
//       //   icon: <i className="bi bi-chevron-right"></i>,
//       //   collapisble: true,
//       // },
//     ],
//   },
// ];

// export default HorizontalSidebarData;

const HorizontalSidebarData = [
  {
    title: "Dashboard",
    href: "/Dashboards/AdminDashboard",
    id: 1,
    suffixColor: "bg-info text-dark-white",
    icon: <i className="bi bi-house" style={{ fontSize: "1rem" }}></i>,
  },
  {
    title: "User Management",
    href: "#",
    id: 2,
    suffixColor: "bg-info text-dark-white",
    icon: <i class="bi bi-person-plus" style={{ fontSize: "1rem" }}></i>,
    collapisble: true,
    children: [
      // {
      //   title: 'ROLE',
      //   href: '/Role/AllRole',
      //   id: 6,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right"></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'Business Partner',
      //   href: '/UseManagement/BusinessPartner',
      //   id: 6,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right"></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'Master Distributor',
      //   href: '/UseManagement/MasterDistributor',
      //   id: 10,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right"></i>,
      //   collapisble: true,
      // },
      {
        title: "Super Distributor",
        href: "/UserManagement/SuperDistributor",
        id: 6,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "Distributor Master",
        href: "/Distributor/Distributor",
        id: 10,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "Retailer Master",
        href: "/Retailer/Retailer",
        id: 14,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      // {
      //   title: 'API User Master',
      //   href: '/Admin/AdminAPIUserMaster',
      //   id: 14,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right"></i>,
      //   collapisble: true,
      // },
      {
        title: "API Partner",
        href: "/Admin/AdminAPIPartner",
        id: 14,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "User Service Status",
        href: "/Admin/UserServiceStatus",
        id: 14,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "User KYC Pending",
        href: "/Admin/PendingKycVerification",
        id: 14,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      // {
      //   title: 'Employee List',
      //   href: '/UseManagement/Employee',
      //   id: 14,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right"></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'User Request',
      //   href: '/Admin/UserRequest',
      //   id: 14,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right"></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'Set User KYC Amount',
      //   href: '/Admin/SetUserKycAmount',
      //   id: 14,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right"></i>,
      //   collapisble: true,
      // },
    ],
  },

  {
    title: "Balance Request",
    href: "#",
    id: 2,
    suffixColor: "bg-info text-dark-white",
    icon: <i class="bi bi-cash-coin" style={{ fontSize: "1rem" }}></i>,
    collapisble: true,
    children: [
      {
        title: "Balance Request",
        href: "/Admin/ViewBalanceRequestAdmin",
        id: 6,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
    ],
  },
  {
    title: "Commission",
    href: "#",
    id: 2,
    suffixColor: "bg-info text-dark-white",
    icon: <i class="bi bi-currency-rupee"></i>,
    collapisble: true,
    children: [
      {
        title: "Commission Master Template",
        href: "/Admin/CommissionMasterTemplate",
        id: 6,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "Plan",
        href: "/Admin/AllPlans",
        id: 10,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "Commission Plan Master",
        href: "/Admin/GetCommissionPlanMaster",
        id: 6,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
    ],
  },
  {
    title: "Credit Debit Details",
    href: "#",
    id: 2,
    suffixColor: "bg-info text-dark-white",
    icon: <i class="bi bi-piggy-bank" style={{ fontSize: "1rem" }}></i>,
    collapisble: true,
    children: [
      {
        title: "Admin Self TopUp",
        href: "/ManageCreditDetails/CreditSelfAdmin",
        id: 6,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "Direct TopUp User",
        href: "/ManageCreditDetails/CreditDebitUsers",
        id: 7,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      // {
      //   title:'Direct TopUp Reports',
      //   href: '/Reports/AdminTopUpReport',
      //   id: 8,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right style={{ fontSize: '1rem' }}"></i>,
      //   collapisble: true,
      // },
      // {
      //   title:'Credit Debit Details Reports',
      //   href: '/ManageCreditDetails/CreditDebitDetails',
      //   id: 9,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right style={{ fontSize: '1rem' }}"></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'Plan',
      //   href: '/PlanMaster/AllPlans',
      //   id: 10,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right style={{ fontSize: '1rem' }}"></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'Commission Plan',
      //   href: '/CommissionMaster/AllCommissionPlans',
      //   id: 14,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right style={{ fontSize: '1rem' }}"></i>,
      //   collapisble: true,
      // },
    ],
  },
  {
    title: "Manage Bank",
    href: "#",
    id: 2,
    suffixColor: "bg-info text-dark-white",
    icon: <i class="bi bi-bank" style={{ fontSize: "1rem" }}></i>,
    collapisble: true,
    children: [
      {
        title: "Manage Self Bank",
        href: "/Admin/AdminSelfBank",
        id: 6,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "Manage Main Bank",
        href: "/Admin/MainBankList",
        id: 6,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "Bank Data Manager",
        href: "/Admin/BankDataManager",
        id: 6,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "Payment Operator",
        href: "/Admin/PaymentOperatorList",
        id: 6,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
    ],
  },
  {
    title: "Management",
    href: "#",
    id: 8,
    suffixColor: "bg-info text-dark-white",
    icon: <i class="bi bi-sliders" style={{ fontSize: "1rem" }}></i>,
    collapisble: true,
    children: [
      // {
      //   title:'Business Rights',
      //   href: '/Admin/BusinessRights',
      //   id: 44,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right style={{ fontSize: '1rem' }}"></i>,
      //   collapisble: true,
      // },
      {
        title: "Systems Setting",
        href: "/Admin/SystemSetting",
        id: 10,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "Service Control",
        href: "/Admin/ServicesControl",
        id: 76,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "Login History",
        href: "/Admin/LoginHistory",
        id: 80,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "Complaint Manager",
        href: "/Admin/ComplaintManager",
        id: 6,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "Update CustomerLimit",
        href: "/Admin/CustomerLimitInc",
        id: 86,
        suffixColor: "#ef7586",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "Update Customer Name",
        href: "/Admin/CustomerNameUpdate",
        id: 86,
        suffixColor: "#ef7586",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "Change Tpin",
        href: "/Admin/ChangeTpin",
        id: 86,
        suffixColor: "#ef7586",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "Notification",
        href: "/Admin/Notification",
        id: 93,
        suffixColor: "#ef7586",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      // {
      //   title:'Add Banner',
      //   href: '/Admin/AddBanner',
      //   id: 93,
      //   suffixColor: '#ef7586',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
    ],
  },
  {
    title: "API Config",
    href: "#",
    id: 8,
    suffixColor: "bg-info text-dark-white",
    icon: <i className="bi bi-eye"></i>,
    collapisble: true,
    children: [
      {
        title: "App Config",
        href: "/Admin/appConfig",
        id: 8.1,
        suffixColor: "#4cb5bb",
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      {
        title: "API LOGS",
        href: "/Admin/APILogs",
        id: 8.1,
        suffixColor: "#4cb5bb",
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      // {
      //   title: "API FORWARD SWITCH",
      //   href: "/Admin/APIForwardSwitch",
      //   id: 8.1,
      //   suffixColor: "#4cb5bb",
      //   icon: <i className="bi bi-chevron-right"></i>,
      //   collapisble: true,
      // },
      {
        title: "API REQ/RES BY TxnId",
        href: "/Admin/APIRequestResponseAdmin",
        id: 14,
        suffixColor: "#4cb5bb",
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      {
        title: "SMS Logs",
        href: "/Admin/SMSLogs",
        id: 14,
        suffixColor: "#4cb5bb",
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      {
        title: "Set Charges",
        href: "/Admin/SetCharges",
        id: 14,
        suffixColor: "#4cb5bb",
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
    ],
  },
  {
    title: "Reports",
    href: "#",
    id: 2,
    suffixColor: "bg-info text-dark-white",
    icon: <i class="bi bi-list-columns" style={{ fontSize: "1rem" }}></i>,
    collapisble: true,
    children: [
      {
        title: "User Ledger Reports",
        href: "/Admin/AdminLedgeReports",
        id: 6,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "ADMIN Ledger Reports",
        href: "/Admin/AdminSelfLedger",
        id: 6,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "DMT/Payout Transaction Reports",
        href: "/Admin/PayOutTransactionReports",
        id: 7,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "DMT Transaction Reports",
        href: "/Reports/AdminTransactionReport",
        id: 7,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "Payout Transaction Reports",
        href: "/Reports/PayoutTransactionByStatus",
        id: 7,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "Payment Getway Report",
        href: "/Reports/PaymentGetwayReportsAdmin",
        id: 8,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "Recharge Reports",
        href: "/Reports/RechargeReports",
        id: 9,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "DTH Recharge Reports",
        href: "/Reports/DTHRechargeReports",
        id: 9,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "AEPS Transaction Reports",
        href: "/Reports/AEPSTransactionReportsAdmin",
        id: 9,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "Bill Payment Reports",
        href: "/Reports/AdminBillPaymentReports",
        id: 9,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "Commission Reports",
        href: "/Admin/CommissionReports",
        id: 7,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "Top Up Report",
        href: "/Reports/AdminTopUpReport",
        // id: 6,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      // {
      //   title: 'User Ledger Reconsile Reports',
      //   href: '/Admin/AdminLedgeReconsileReports',
      //   id: 6,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      {
        title: "Change Status By Txn Id",
        href: "/Admin/AdminChangeStatus",
        id: 10.1,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "API Request Response Status",
        href: "/Admin/APIRequestResponseAdmin",
        id: 14,
        suffixColor: "#4cb5bb",
        icon: <i className="bi bi-chevron-right"></i>,
        collapisble: true,
      },
      {
        title: "Cashout Transaction Reports",
        href: "/Admin/CashoutTransactionReports",
        suffixColor: "#4cb5bb",
        icon: <i className="bi bi-chevron-right"></i>,
        id: 13.1,
        collapisble: false,
      },
      {
        title: "Refund Reports",
        href: "/Admin/RefundReports",
        suffixColor: "#4cb5bb",
        icon: <i className="bi bi-chevron-right"></i>,
        id: 13.1,
        collapisble: false,
      },

      // {
      //   title:'KYC Payment Status',
      //   href: '/Reports/KycPaymentsStatus',
      //   id: 9,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      // Ayush 01-07-2024
      // {
      //   title: "Recharge Transactions",
      //   href: "/Reports/AdminRechargeTransactions",
      //   // id: 9,
      //   suffixColor: "#4cb5bb",
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      // {
      //   title: "Recharge Pending Transactions",
      //   href: "/Reports/AdminRechargePendingTransactions",
      //   // id: 9,
      //   suffixColor: "#4cb5bb",
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      // {
      //   title: "Recharge Refund Cases",
      //   href: "/Reports/AdminRechargeRefundCases",
      //   // id: 9,
      //   suffixColor: "#4cb5bb",
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      // {
      //   title: "BBPS CMS Module",
      //   href: "/Reports/AdminBBPSCMSModule",
      //   // id: 9,
      //   suffixColor: "#4cb5bb",
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      // // End
      // {
      //   title: 'Money Transfer Report',
      //   href: '/Reports/AdminMoneyTransferReport',
      //   // id: 9,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'Money Transfer Hold Cases',
      //   href: '/Reports/AdminMoneyTransferHoldCases',
      //   // id: 9,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'AEPS Report',
      //   href: '/Reports/AdminAepsReport',
      //   // id: 9,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'Outstanding Report',
      //   href: '/Reports/OutStandingReportsAdmin',
      //   id: 7,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'CD Report',
      //   href: '/Reports/AdminCDReport',
      //   // id: 9,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'CC Report',
      //   href: '/Reports/AdminCCReport',
      //   // id: 9,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'Payment Getway Transaction Report',
      //   href: '/Reports/AdminPaymentGetwayTransactionReport',
      //   // id: 9,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'Collection Report',
      //   href: '/Reports/AdminCollectionReport',
      //   // id: 9,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'POS Transaction Report',
      //   href: '/Reports/AdminPOSTransactionReport',
      //   // id: 9,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'Wallet To Wallet Transfer Report',
      //   href: '/Reports/AdminWalletToWalletTransferReport',
      //   // id: 9,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
    ],
  },

  {
    title: "Account Report",
    href: "#",
    id: 2,
    suffixColor: "bg-info text-dark-white",
    icon: <i class="bi bi-list-check" style={{ fontSize: "1rem" }}></i>,
    collapisble: true,
    children: [
      // {
      //   title: 'Account Status Report',
      //   href: '/Reports/AccountStatusReports',
      //   // id: 6,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      {
        title: "Payout Transaction Batch",
        href: "/Reports/GetPayoutTransactionBatch",
        // id: 6,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      // {
      //   title: 'Wallet Report',
      //   href: '/Admin/AdminWalletReport',
      //   id: 7,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'Full Day Report',
      //   href: '/Reports/AccountReport/AdminFullDayReport',
      //   // id: 6,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      {
        title: "Business Report",
        href: "/Admin/AdminBusinessReports",
        // id: 6,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      {
        title: "Business Analysis Report",
        href: "/Admin/AdminBusinessReport",
        // id: 6,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      // {
      //   title: 'ICIC Bank Account Statement',
      //   href: '/Reports/AccountReport/AdminICICBankAccontStatStatement',
      //   // id: 6,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'TDS Report Pancardwise ',
      //   href: '/Reports/AccountReport/AdminTDSReportPancardWise',
      //   // id: 6,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'KYC Pending Report',
      //   href: '/Reports/AccountReport/AdminKYCPendingReport',
      //   // id: 6,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'KYC Approve Report',
      //   href: '/Reports/AccountReport/AdminKYCApproveReport',
      //   // id: 6,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'User Report',
      //   href: '/Reports/AccountReport/AdminUserReport',
      //   // id: 6,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'Users List',
      //   href: '/Reports/AccountReport/AdminUserList',
      //   // id: 6,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'Cash Out Report',
      //   href: '/Reports/AccountReport/AdminCashOutReport',
      //   // id: 6,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },

      {
        title: "Daily Business Reports",
        href: "/Reports/AdminDailyBusinessReports",
        // id: 6,
        suffixColor: "#4cb5bb",
        icon: (
          <i className="bi bi-chevron-right" style={{ fontSize: "1rem" }}></i>
        ),
        collapisble: true,
      },
      // {
      //   title: 'DMT Log Report',
      //   href: '/Reports/AccountReport/AdminDMTLogReport',
      //   // id: 6,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
      // {
      //   title: 'Verification Charge Report',
      //   href: '/Reports/AccountReport/AdminVirificationChargeReport',
      //   // id: 6,
      //   suffixColor: '#4cb5bb',
      //   icon: <i className="bi bi-chevron-right" style={{ fontSize: '1rem' }}></i>,
      //   collapisble: true,
      // },
    ],
  },
];

export default HorizontalSidebarData;
