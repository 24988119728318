import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container } from "reactstrap";
import Header from "./header/Header";
import Sidebar from "./sidebars/vertical/Sidebar";
import HorizontalHeader from "./header/HorizontalHeader";
import HorizontalSidebar from "./sidebars/horizontal/HorizontalSidebar";
import AppLogout from "../components/common/AppLogout";

const SuperLayout = () => {
  const toggleMiniSidebar = useSelector(
    (state) => state.customizer.isMiniSidebar
  );
  const showMobileSidebar = useSelector(
    (state) => state.customizer.isMobileSidebar
  );
  const topbarFixed = useSelector((state) => state.customizer.isTopbarFixed);
  const LayoutHorizontal = useSelector(
    (state) => state.customizer.isLayoutHorizontal
  );
  const isFixedSidebar = useSelector(
    (state) => state.customizer.isSidebarFixed
  );
  
  return (
    <main className="super-distributor-bg">
    <div className={`pageWrapper d-md-block ${ toggleMiniSidebar ? "isMiniSidebar" : ""}`}>
      <div className={`contentArea ${topbarFixed ? "fixedTopbar" : ""}`}>
        <AppLogout>
          {/********header**********/}
          {LayoutHorizontal ? <HorizontalHeader /> : <Header />}
          {LayoutHorizontal ? <HorizontalSidebar /> : ""}
          
          <div className="boxContainer d-lg-flex">
            {/********Aside Content**********/}
            {LayoutHorizontal ? (
              ""
            ) : (
              <aside className={`sidebarArea ${showMobileSidebar ? "showSidebar" : ""}`}>
                <Sidebar />
              </aside>
            )}
            {/********Aside Content**********/}
            <Container fluid className="super-outlet">
              <Outlet />
            </Container>
          </div>
        </AppLogout>
      </div>
    </div>
  </main>
  );
};

export default SuperLayout;
