import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getSeniorDetails,
  userDeleteInitial,
  userDeleteFailed,
} from "../../slices/retailer/UserAppSlice/UserDetailsSlice";

import "./MyDistributorPopBox.css";

const MyDistributorPopBox = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [srFName, setSrFName] = useState("");
  const [srLName, setSrLName] = useState("");
  const [srEmail, setSrEmail] = useState("");
  const [srMobNum, setSrMoNumber] = useState("");
  const { user: currentUser } = useSelector((state) => state.user);
  const { token: Usertoken } = useSelector((state) => state.user);
  
  useEffect(() => {
    setLoading(true);
    dispatch(getSeniorDetails({ currentUser, Usertoken }))
      .unwrap()
      .then((res) => {
        setLoading(false);
        if (res.status.type === "success") {
          dispatch(userDeleteInitial());
          setSrFName(res.data[0].senior_FirstName);
          setSrLName(res.data[0].senior_LastName);
          setSrEmail(res.data[0].senior_Email);
          setSrMoNumber(res.data[0].senior_Mobile);
        } else {
          dispatch(userDeleteFailed(res));
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);
  
  return (
    <>
      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" loading={loading}>
        <div className="modal-dialog modal-maxwidth">
          <div className="modal-content">  
            <div className="modal-body">
              <h5>Distributor & District Lead Details</h5>
                <div className="content-box">
                  <span>
                    <i class="fas fa-user dicon"></i> {srFName} {srLName}
                  </span>
                  <span>
                    <i class="fas fa-phone dicon"></i> {srMobNum}
                  </span>
                  <span>
                    <i class="fas fa-envelope dicon"></i>{srEmail}
                  </span>
                </div>
              <button type="button" className="btn btn-secondary closebtn" data-bs-dismiss="modal">
              Close
            </button>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyDistributorPopBox;
